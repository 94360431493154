<template>
  <div class="grid row">
    <div class="flex xs12">
      <va-card title="Редагування Partnership" class="fill-height">
        <div>
          <div class="row">
            <div class="flex xs12">
              <va-tabs v-model="tabValue" style="width: 100%; min-width: 250px; margin-bottom: 25px;">
                <va-tab
                  v-for="title in tabTitles"
                  :key="title"
                >
                  {{title}}
                </va-tab>
              </va-tabs>
              <div class="flex xs12" v-if="tabValue === 0">
                <va-card class="mb-3" title="Top Image">
                  <div v-if="topImageExisting">
                    <a style="display: block;" target="_blank" :href="topImageExisting"><img style="max-width: 400px;" :src="topImageExisting"></a>
                    <div><va-button small color="danger" @click="topImageExisting = deleteImage(topImageId) ? '' : topImageExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    @input="delete errors.topImage"
                    v-model="topImage"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage('topImage', 'top-image', 'Partnership[topImage]', topImage[0])">Завантажити</va-button>
                  <div v-if="errors.topImage" style="color: red;">{{ errors.topImage[0] }}</div>
                </va-card>
                <va-card class="mb-3" title="Top Mobile Image">
                  <div v-if="topMobileImageExisting">
                    <a style="display: block;" target="_blank" :href="topMobileImageExisting"><img style="max-width: 400px;" :src="topMobileImageExisting"></a>
                    <div><va-button small color="danger" @click="topMobileImageExisting = deleteImage(topMobileImageId) ? '' : topMobileImageExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    @input="delete errors.topMobileImage"
                    v-model="topMobileImage"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage('topMobileImage', 'top-mobile-image', 'Partnership[topMobileImage]', topMobileImage[0])">Завантажити</va-button>
                  <div v-if="errors.topMobileImage" style="color: red;">{{ errors.topMobileImage[0] }}</div>
                </va-card>

                <va-card class="mb-3" title="Presentation File">
                  <div v-if="presentationFileExisting">
                    <a class="link" style="display: block;" target="_blank" :href="presentationFileExisting">{{ presentationFileExisting }}</a>
                    <div><va-button small color="danger" @click="presentationFileExisting = deleteImage(presentationFileId) ? '' : presentationFileExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити новий файл</span>
                  <va-file-upload
                    dropzone
                    v-model="presentationFile"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage('presentationFile', 'presentationFile', 'Partnership[presentationFile]', presentationFile[0])">Завантажити</va-button>
                  <div v-if="errors.presentationFile" style="color: red;">{{ errors.presentationFile[0] }}</div>
                </va-card>
                <va-input
                  label="Download Button Text"
                  v-model="downloadButtonText"
                  @input="delete errors.downloadButtonText"
                  :error="!!errors.downloadButtonText"
                  :error-messages="errors.downloadButtonText"
                />
                <va-input
                  label="Breadcrumb Label *"
                  v-model="breadcrumbLabel"
                  @input="delete errors.breadcrumbLabel"
                  :error="!!errors.breadcrumbLabel"
                  :error-messages="errors.breadcrumbLabel"
                />
                <va-input
                  label="Title *"
                  v-model="title"
                  @input="delete errors.title"
                  :error="!!errors.title"
                  :error-messages="errors.title"
                />
                <va-input
                  label="Description *"
                  v-model="description"
                  @input="delete errors.description"
                  :error="!!errors.description"
                  :error-messages="errors.description"
                />
                <va-input
                  label="First Logistic Title *"
                  v-model="firstLogisticTitle"
                  @input="delete errors.firstLogisticTitle"
                  :error="!!errors.firstLogisticTitle"
                  :error-messages="errors.firstLogisticTitle"
                />
                <va-input
                  type="textarea"
                  label="First Logistic Description"
                  v-model="firstLogisticDescription"
                  @input="delete errors.firstLogisticDescription"
                  :error="!!errors.firstLogisticDescription"
                  :error-messages="errors.firstLogisticDescription"
                />
                <va-input
                  label="Work With Us Title *"
                  v-model="workWithUsTitle"
                  @input="delete errors.workWithUsTitle"
                  :error="!!errors.workWithUsTitle"
                  :error-messages="errors.workWithUsTitle"
                />
                <va-card class="mb-3" title="Work With Us Items">
                  <va-button
                    class="mb-3"
                    :to="{ name: '1lk-partnership-edit' }"
                  >
                    Додати
                  </va-button>
                  <va-button
                    class="mb-3"
                    :to="{ name: '1lk-partnership' }"
                  >
                    Всі
                  </va-button>
                  <div class="row">
                    <div
                      v-for="block in blocks"
                      :key="block.id"
                      stripe="success"
                      class="flex xs4 mb-2">
                      <va-card
                      >
                        <div class="mb-1">Label: {{ block.label }} </div>
                        <div class="mb-1">Content: {{ block.content }} </div>
                        <va-button
                          small
                          color="info"
                          :to="{ name: '1lk-partnership-edit', params: { id: block.id } }"
                        >
                          Редагувати
                        </va-button>
                      </va-card>
                    </div>
                  </div>
                </va-card>
                <va-input
                  label="Principe Title *"
                  v-model="principeTitle"
                  @input="delete errors.principeTitle"
                  :error="!!errors.principeTitle"
                  :error-messages="errors.principeTitle"
                />
                <va-input
                  type="textarea"
                  label="Principe Description"
                  v-model="principeDescription"
                  @input="delete errors.principeDescription"
                  :error="!!errors.principeDescription"
                  :error-messages="errors.principeDescription"
                />
                <va-input
                  label="Partners Block Title *"
                  v-model="partnersBlockTitle"
                  @input="delete errors.partnersBlockTitle"
                  :error="!!errors.partnersBlockTitle"
                  :error-messages="errors.partnersBlockTitle"
                />
                <va-card class="mb-3" title="Partners">
                  <va-button
                    class="mb-3"
                    :to="{ name: '1lk-home-partners-edit' }"
                  >
                    Додати
                  </va-button>
                  <va-button
                    class="mb-3"
                    :to="{ name: '1lk-home-partners' }"
                  >
                    Всі
                  </va-button>
                  <div class="row">
                    <div
                      v-for="partner in partners"
                      :key="partner.id"
                      stripe="success"
                      class="flex xs4 mb-2">
                      <va-card
                      >
                        <img style="max-height: 30px;" :src="apiUrl + partner.image">
                        <div class="mb-1">{{ partner.label }} </div>
                        <va-button
                          small
                          color="info"
                          :to="{ name: '1lk-home-partners-edit', params: { id: partner.id } }"
                        >
                          Редагувати
                        </va-button>
                      </va-card>
                    </div>
                  </div>
                </va-card>
                <va-button @click="submit()">Save</va-button>
              </div>
              <div class="flex xs12" v-if="tabValue === 1">
                <va-input
                  label="Meta Title"
                  v-model="metaTitle"
                  @input="delete errors.metaTitle"
                  :error="!!errors.metaTitle"
                  :error-messages="errors.metaTitle"
                />
                <va-input
                  label="Meta Description"
                  v-model="metaDescription"
                  @input="delete errors.metaDescription"
                  :error="!!errors.metaDescription"
                  :error-messages="errors.metaDescription"
                />
                <va-input
                  label="OG Title"
                  v-model="OgTitle"
                  @input="delete errors.OgTitle"
                  :error="!!errors.OgTitle"
                  :error-messages="errors.OgTitle"
                />
                <va-input
                  label="OG Description"
                  v-model="OgDescription"
                  @input="delete errors.OgDescription"
                  :error="!!errors.OgDescription"
                  :error-messages="errors.OgDescription"
                />
                <va-card class="mb-3" title="OG Image">
                  <div v-if="OgImageExisting">
                    <a style="display: block;" target="_blank" :href="OgImageExisting"><img style="max-width: 400px;" :src="OgImageExisting"></a>
                    <div><va-button small color="danger" @click="OgImageExisting = deleteImage(OgImageId) ? '' : OgImageExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    v-model="OgImage"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage('image', null, 'SeoEntity[image]', OgImage[0], 'common\\models\\SeoEntity')">Завантажити</va-button>
                  <div v-if="errors.image" style="color: red;">{{ errors.image[0] }}</div>
                </va-card>
                <va-input
                  label="seo text title"
                  v-model="seoTextTitle"
                  @input="delete errors.seoTextTitle"
                  :error="!!errors.seoTextTitle"
                  :error-messages="errors.seoTextTitle"
                />
                <div style="margin-bottom: 15px;">
                  <span>seo text</span>
                  <ckeditor v-model="seoText" :editor="editor" :config="editorConfig"></ckeditor>
                </div>
                <va-input
                  label="seo text see more text"
                  v-model="seoTextSeeMore"
                  @input="delete errors.seoTextSeeMore"
                  :error="!!errors.seoTextSeeMore"
                  :error-messages="errors.seoTextSeeMore"
                />
                <va-input
                  label="Seo Text Hide Text"
                  v-model="seoTextHide"
                  @input="delete errors.seoTextHide"
                  :error="!!errors.seoTextHide"
                  :error-messages="errors.seoTextHide"
                />
                <va-button @click="submit()">Save</va-button>
              </div>
            </div>
          </div>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import * as axios from 'axios'
import Vue from 'vue'
import CKEditor from '@ckeditor/ckeditor5-vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import '@ckeditor/ckeditor5-build-classic/build/translations/uk'

export default {
  name: 'grid',
  components: {
    ckeditor: CKEditor.component,
  },
  data () {
    return {
      topImage: [],
      topImageId: '',
      topImageExisting: '',
      topMobileImage: [],
      topMobileImageId: '',
      topMobileImageExisting: '',
      presentationFile: [],
      presentationFileId: '',
      presentationFileExisting: '',
      downloadButtonText: '',
      breadcrumbLabel: '',
      title: '',
      description: '',
      firstLogisticTitle: '',
      firstLogisticDescription: '',
      workWithUsTitle: '',
      blocks: [],
      principeTitle: '',
      principeDescription: '',
      partnersBlockTitle: '',
      partners: [],

      seoId: null,
      metaTitle: '',
      metaDescription: '',
      OgTitle: '',
      OgDescription: '',
      OgImage: [],
      OgImageId: '',
      OgImageExisting: '',
      seoTextTitle: '',
      seoText: '',
      seoTextSeeMore: '',
      seoTextHide: '',

      editor: ClassicEditor,
      editorConfig: {
        language: 'uk',
        simpleUpload: {
          uploadUrl: `${process.env.VUE_APP_1LK_API_URL}/admin/editor/upload`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        },
      },

      errors: [],

      tabTitles: ['Main', 'Seo'],
      tabValue: 0,
    }
  },
  created () {
    this.fetch()
    const generateRandomString = (length = 6) => Math.random().toString(20).substr(2, length)
    this.sign = generateRandomString(32)
  },
  computed: {
    computedStyle () {
      return {
        backgroundColor: this.$themes.primary,
      }
    },
    locale () {
      return Vue.i18n.locale()
    },
    apiUrl () {
      return process.env.VUE_APP_1LK_ADMIN
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
  methods: {
    uploadImage (attr, entityAttribute, param, file, model = 'common\\modules\\kvs\\models\\Partnership') {
      if (!file) {
        return
      }
      const formData = new FormData()
      formData.append('model_name', model)
      formData.append('attribute', attr)
      formData.append('entity_attribute', entityAttribute)
      formData.append(param, file)
      formData.append('file_id', '0')
      formData.append('multiple', '0')
      if (entityAttribute === null) {
        formData.append('id', this.seoId ? this.seoId : '0')
      } else {
        formData.append('id', '0')
        formData.append('entity_attribute', entityAttribute)
      }

      axios.post(`${process.env.VUE_APP_1LK_API_URL}/admin/upload?lang=${this.locale}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(response => {
          this.showToast('Successful upload')
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
    async deleteImage (id) {
      return axios.delete(`${process.env.VUE_APP_1LK_API_URL}/admin/upload/${id}?lang=${this.locale}`)
        .then(() => {
          this.showToast('Successfully deleted')
        })
        .catch(() => {
          this.showToast('Error')
        })
    },
    submit () {
      const data = {
        breadcrumbLabel: this.breadcrumbLabel,
        title: this.title,
        description: this.description,
        downloadButtonText: this.downloadButtonText,
        firstLogisticTitle: this.firstLogisticTitle,
        firstLogisticDescription: this.firstLogisticDescription,
        workWithUsTitle: this.workWithUsTitle,
        principeTitle: this.principeTitle,
        principeDescription: this.principeDescription,
        partnersBlockTitle: this.partnersBlockTitle,

        SeoEntity: {
          title: this.metaTitle,
          description: this.metaDescription,
          og_title: this.OgTitle,
          og_description: this.OgDescription,
          seo_text_title: this.seoTextTitle,
          seo_text: this.seoText,
          seo_text_see_more_text: this.seoTextSeeMore,
          seo_text_hide_text: this.seoTextHide,
        },
      }
      axios.post(`${process.env.VUE_APP_1LK_API_URL}/admin/partnership-text?lang=${this.locale}`, data)
        .then(response => {
          this.showToast('Success')
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          }
          console.log(error)
          this.showToast('Error')
        })
    },
    fetch () {
      axios.get(`${process.env.VUE_APP_1LK_API_URL}/admin/partnership-text?lang=${this.locale}`)
        .then(response => {
          this.topImageExisting = response.data.topImage ? process.env.VUE_APP_1LK_ADMIN + response.data.topImage : ''
          this.topImageId = response.data.topImageId
          this.topMobileImageExisting = response.data.topMobileImage ? process.env.VUE_APP_1LK_ADMIN + response.data.topMobileImage : ''
          this.topMobileImageId = response.data.topMobileImageId
          this.presentationFileExisting = response.data.presentationFile ? process.env.VUE_APP_1LK_ADMIN + response.data.presentationFile : ''
          this.presentationFileId = response.data.presentationFileId
          this.breadcrumbLabel = response.data.breadcrumbLabel
          this.title = response.data.title
          this.description = response.data.description
          this.downloadButtonText = response.data.downloadButtonText
          this.firstLogisticTitle = response.data.firstLogisticTitle
          this.firstLogisticDescription = response.data.firstLogisticDescription
          this.workWithUsTitle = response.data.workWithUsTitle
          this.principeTitle = response.data.principeTitle
          this.principeDescription = response.data.principeDescription
          this.partnersBlockTitle = response.data.partnersBlockTitle
          this.partners = response.data.partners
          this.blocks = response.data.blocks

          this.seoId = response.data.seo.id
          this.metaTitle = response.data.seo.title
          this.metaDescription = response.data.seo.description
          this.OgTitle = response.data.seo.og_title
          this.OgDescription = response.data.seo.og_description
          this.seoTextTitle = response.data.seo.seo_text_title
          this.seoText = response.data.seo.seo_text ? response.data.seo.seo_text : ''
          this.seoTextSeeMore = response.data.seo.seo_text_see_more_text
          this.seoTextHide = response.data.seo.seo_text_hide_text

          this.OgImageExisting = response.data.seo.og_image ? process.env.VUE_APP_1LK_ADMIN + response.data.seo.og_image : null
          this.OgImageId = response.data.seo.og_image_id
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
  },
}
</script>

<style lang="scss">
.grid {
  &__container {
    min-height: 3rem;
    color: $white;
    border-radius: 0.5rem;
  }
}

.va-file-upload-gallery-item {
  flex-basis: unset;
  max-width: 400px;
  width: unset;
}
</style>
